import React, { useState, useRef, useEffect, useCallback } from "react";
import Typed from "react-typed";
import "./App.css";
import audioFile from "./candycrush.mp3";

const stringsToType = [
  "DJX.\n",
  "a boy from the mountains\ntravels to spread a message\nto the world.",
  "some call him a delinquent...",
  "some call him a mystikal outlaw...",
  "but...\n\nfrom his perspective?",
  "DARIAN JUST EXISTS...",
  "DJX IS 4 DA KIDDZ",
  "DJX\n\n4 DA KIDDZ\n\n@DJX.ARCHIVE",
];

const imagesToFlash = [
  "/img/3_gray.png",
  "/img/recap1006.png",
  "/img/aladdin1579.png",
  "/img/recap1006_gray.png",
  "/img/recap1b_gray.gif",
  "/img/recap2b_gray.gif",
  "/img/recap3b_gray.gif",
  "/img/render_gray.gif",
  "/img/aladdin1.gif",
  "/img/aladdin2.gif",
  "/img/render_gray.gif",
  "/img/hollywood.gif",
  "/img/blessed.gif",
  "/img/gif 1_gray.gif",
  "/img/gif 2_gray.gif",
  "/img/gif 3_gray.gif",
  "/img/gif 4_gray.gif",
  "/img/gif 5_gray.gif",
  "/img/gif 6.gif",
  "/img/gif 7.gif",
  "/img/gif 12.gif",
  "/img/gif 16.gif",
  "/img/gif 18.gif",
  "/img/gif 19.gif",
  "/img/gif 20.gif",
  "/img/gif 21.gif",
  "/img/gif 22.gif",
  "/img/gif 23.gif",
  "/img/gif 24.gif",
  "/img/gif 26.gif",
  "/img/gif 27.gif",
  "/img/gif 28.gif",
  "/img/gif 29.gif",
];

imagesToFlash.forEach((src) => {
  const img = new Image();
  img.src = src;
});

function getImageDisplayDuration(imagePath) {
  return imagePath.endsWith(".gif") ? 250 : 100;
}

function App() {
  const [currentImage, setCurrentImage] = useState(null);
  const [stringIndex, setStringIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true); // Initialize to true
  const [isNavOpen, setIsNavOpen] = useState(false); // Control navigation menu visibility
  const imageTimeoutRef = useRef(null);
  const flashingImagesRef = useRef([]);
  const navIconRef = useRef(null);
  const navMenuRef = useRef(null);
  const audioRef = useRef(null);

  // Function to flash images
  const flashImages = useCallback(() => {
    if (flashingImagesRef.current.length) {
      const nextImage = flashingImagesRef.current.pop();
      setCurrentImage(nextImage);
      imageTimeoutRef.current = setTimeout(
        flashImages,
        getImageDisplayDuration(nextImage),
      );
    } else {
      setCurrentImage(null);
      setStringIndex((prevIndex) => (prevIndex + 1) % stringsToType.length);
    }
  }, []);

  // Function to start flashing images
const startFlashingImages = useCallback(() => {
  const availableImages = [...imagesToFlash]; // Create a copy of the array
  flashingImagesRef.current = [];

  while (flashingImagesRef.current.length < 4 && availableImages.length > 0) {
    const randomIndex = Math.floor(Math.random() * availableImages.length);
    const nextImage = availableImages.splice(randomIndex, 1)[0]; // Remove the selected image from availableImages
    flashingImagesRef.current.push(nextImage);
  }

  flashImages();
}, [flashImages])


  useEffect(() => {
    // Preload images
    const preloadImages = (imageList, callback) => {
      let loadedCount = 0;
      imageList.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedCount++;
          if (loadedCount === imageList.length) {
            callback();
          }
        };
      });
    };

    preloadImages(imagesToFlash, () => {
      startFlashingImages();
    });

    // Initiate audio playback when the component mounts
    audioRef.current = new Audio(audioFile);
    audioRef.current.play().catch((error) => {
      console.error("Audio playback error:", error);
    });

    // Ensure that audio is initially paused
    audioRef.current.pause();
    setIsPlaying(false);
  }, [startFlashingImages]);

  // Function to toggle audio playback
  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error("Audio playback error:", error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  // Function to toggle navigation menu
  const toggleNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="retro-screen">
      <div className="App">
        {/* Container for icons */}

        <div className="icon-container">
          {/* Play/pause button */}
          <div
            className={`audio-control-button ${isPlaying ? "pause" : "play"}`}
            onClick={toggleAudio}
          >
            <i className={`fa fa-${isPlaying ? "pause" : "play"}`} />
          </div>
          {/* Dot icon to open/close navigation menu */}
          <div className="nav-icon" ref={navIconRef} onClick={toggleNavMenu}>
            &#8226;
          </div>
        </div>

        {/* Navigation menu */}
        <ul className={`nav-menu ${isNavOpen ? "open" : ""}`} ref={navMenuRef}>
          <li>
            <a
              href="https://instagram.com/djx.archive?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              rel="noreferrer"
            >
              instagram
            </a>
          </li>
          <li>
            <a
              href="https://on.soundcloud.com/iekBy"
              target="_blank"
              rel="noreferrer"
            >
              soundcloud
            </a>
          </li>
          <li>
            <a
              href="https://spotify.link/tTyJFPvLEDb"
              target="_blank"
              rel="noreferrer"
            >
              spotify
            </a>
          </li>
          <li className="email-nav">gear7mgmt@gmail.com</li>
        </ul>

        <Typed
          key={stringIndex}
          strings={[stringsToType[stringIndex]]}
          typeSpeed={40}
          backSpeed={10}
          backDelay={2000}
          loop={false}
          onComplete={() => {
            setTimeout(startFlashingImages, 2000);
          }}
        />
        {currentImage && (
          <img src={currentImage} className="flashimg" alt="" />
        )}
        <p className="email">gear7mgmt@gmail.com</p>
      </div>
      <div className="scanlines"></div>
    </div>
  );
}

export default App;
